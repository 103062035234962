angular.module('on-off-solunion', [
  'ngRoute',
  'game'
])
.config(['$sceDelegateProvider', '$routeProvider', '$httpProvider', 'mediaURL',
  function ($sceDelegateProvider, $routeProvider, $httpProvider, mediaURL) {

    // Example on how to override a template from game
    // $routeProvider.when('/results', {
    //   templateUrl: 'app/partials/results.html',
    //   title: ' Resumen Global '
    // });
    $routeProvider.when('/', {
      templateUrl: 'app/partials/intro-page/intro-page.html',
      title: ' Aiwin '
    });

    $routeProvider.when('/challenge/:id', {
      templateUrl: 'app/partials/challenge-page/challenge-page.html',
      title: ''
    });

    $routeProvider.when('/registration', {
      templateUrl: 'app/partials/registration-page/registration-page.html',
      title: ' Registration'
    });

  }
]).run(function(gameSettings) {

  // Overwrite game configuration
  gameSettings.video.autostart = false;
  gameSettings.video.skin = 'on-off';
  gameSettings.home.randomSelection = false;
  gameSettings.intro.videoResponsive = false;
  gameSettings.rewards.active = false;
  gameSettings.login.redirectToChallenge = true;
  gameSettings.settings.ranking.userLogo = 'images/common/icons/icon-user-48x48.png';
  gameSettings.settings.ranking.hasCustomRanking = false;
  gameSettings.settings.site.logo = 'images/common/v2/main/project-361x62.png';
  gameSettings.settings.site.name = 'ON/OFF SOLUNION';
  gameSettings.settings.client.logo = 'images/common/v2/main/client-321x65.png';
  gameSettings.settings.client.name = 'ON/OFF SOLUNION';
  gameSettings.settings.profile.statisticColor1 = '#AEDEAD';
  gameSettings.settings.profile.statisticColor2 = '#007F83';
  gameSettings.video.subtitles.active = true;
  gameSettings.video.subtitles.autostart = true;
  gameSettings.video.fullscreen.active = true;
  gameSettings.globalAudio.challenge = 0;
  gameSettings.settings.challengesPreviewGifs = true;
  gameSettings.settings.languages = [
    {key: 'es', name: 'ES'}
  ];
  gameSettings.settings.videoPlayer.settings.captions.fontSize = 13;
  gameSettings.settings.timerBar.colorPrimary = '#AEDEAD';
  gameSettings.settings.timerBar.colorSecondary = '#F0FAF0';
  gameSettings.settings.timerBar.gradient = false;
  gameSettings.intro.signUpEnabled = false;
  gameSettings.settings.showPreviewOnFinishWithZeroPoints = false;
  gameSettings.settings.failureBehaviour = {
    mode: 'showStatisticsAfterFinish'
  };

  gameSettings.settings.backgroundsMapping = {
    "intro": "images/on-off-solunion/main/background/on-off-background.jpg",
    "results": "images/on-off-solunion/main/background/on-off-background.jpg",
    "home": "images/on-off-solunion/main/background/on-off-background.jpg",
    "challenge": "images/on-off-solunion/main/background/on-off-background.jpg",
    "login": "images/on-off-solunion/main/background/on-off-background.jpg",
    "default": "images/on-off-solunion/main/background/on-off-background.jpg",
  };

  // gameSettings.settings.extraLinks = [
  //   {
  //     key: 'blog',
  //     name: 'Blog',
  //     link: 'https://aiwin.io/es/blog/category/diversidad/',
  //     icon: 'images/common/v2/icons/aiwin-icon-32x32.png'
  //   },
  //   {
  //     key: 'store',
  //     name: 'Store',
  //     link: 'https://aiwin.io/es/store/#!/she?collection=wYvlVsWKKt',
  //     icon: 'images/common/v2/icons/aiwin-store-icon-32x32.png'
  //   },
  //   {
  //     key: 'linkedin',
  //     name: 'Linkedin',
  //     link: 'https://www.linkedin.com/feed/hashtag/aiwinshe/',
  //     icon: 'images/common/v2/icons/linkedin-icon-32x32.png'
  //   },
  //   {
  //     key: 'twitter',
  //     name: 'Twitter',
  //     link: 'https://twitter.com/search?q=AiwinSHE',
  //     icon: 'images/common/v2/icons/twitter-icon-32x32.png'
  //   },
  //   {
  //     key: 'facebook',
  //     name: 'Facebook',
  //     link: 'https://www.facebook.com/hashtag/aiwinshe',
  //     icon: 'images/common/v2/icons/facebook-icon-32x32.png'
  //   }
  // ];

}).run(function ($rootScope, config, gameSettings) {
  if (gameSettings.settings.backgroundsMapping) {
    // Preload background images
    // Object.keys(gameSettings.settings.backgroundsMapping).forEach(function (key) {
    //   var backgroundImage = gameSettings.settings.backgroundsMapping.key;
    //   var my_image = new Image();
    //   my_image.src = $rootScope.contentURL + backgroundImage;
    // });

    $rootScope.$on("pageChange", function (evt, data) {
      angular.element('body').attr('page', data.page);
      var backgroundKey = "default";
      if (gameSettings.settings.backgroundsMapping[data.page] !== undefined) {
        backgroundKey = data.page;
      }
      var background = gameSettings.settings.backgroundsMapping[backgroundKey];
      angular.element(".view-wrapper").css("backgroundImage", "url('" + evt.currentScope.contentURL + background + "')");
    });
  }
});
